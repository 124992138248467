import { useCallback, useMemo, useEffect, useState } from 'react';

import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import { Setting2 } from 'iconic-react';

import { useTokenContext } from 'context/token.context';

import ConnectWallet from 'components/ConnectWallet';
import TokenInput, { FALLBACK_ADDRESS } from 'components/TokenInput';
import PriceDisplay from 'components/PriceDisplay';

import { giffAbi, pGiffAbi } from 'constants/abi';
import { TokenTypes } from 'constants/constants';

import Pgiff from 'assets/images/pgiff.webp';
import Giff from 'assets/images/giff.webp';
import Pls from 'assets/images/pls.webp';
import { showNotification } from 'libs/libs';

const Minter = () => {
  const { address } = useAccount();
  const { plsAmountInteger, giffAmount, giffBalance } = useTokenContext();
  const { data: hash, writeContract, status, error } = useWriteContract();

  const [isMintable, setIsMintable] = useState(false);

  const { data: giffAllowance, refetch } = useReadContract({
    address: process.env.REACT_APP_GIFF_CONTRACT_ADDRESS as `0x${string}`,
    abi: giffAbi || [],
    functionName: 'allowance',
    args: [
      address || FALLBACK_ADDRESS,
      process.env.REACT_APP_PGIFF_CONTRACT_ADDRESS as `0x${string}`
    ]
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);

    return () => clearInterval(interval);
  }, [refetch]);

  const { isLoading } = useWaitForTransactionReceipt({ hash });

  useEffect(() => {
    if (error) {
      showNotification((error as any)?.shortMessage || 'Something went wrong', 'error');
    }
  }, [error]);

  useEffect(() => {
    if (isLoading === false && status === 'success') {
      showNotification('Transaction successful', 'success');
    }
  }, [isLoading, status]);

  const needAllowance = useMemo(() => {
    const giffAllowanceBigInt: BigInt = (giffAllowance as BigInt) || 0n;
    return parseInt(giffAmount?.toString()) > parseInt(giffAllowanceBigInt?.toString());
  }, [giffAllowance, giffAmount]);

  useEffect(() => {
    if (!needAllowance && giffAmount !== 0n) {
      setIsMintable(true);
    } else {
      setIsMintable(false);
    }
  }, [giffAmount, needAllowance]);

  const handleApprove = useCallback(async () => {
    if (giffAmount > giffBalance) {
      showNotification('Insufficient GIFF balance', 'error');
      return;
    }

    writeContract({
      address: process.env.REACT_APP_GIFF_CONTRACT_ADDRESS as `0x${string}`,
      abi: giffAbi || [],
      functionName: 'approve',
      args: [
        process.env.REACT_APP_PGIFF_CONTRACT_ADDRESS as `0x${string}`,
        BigInt(giffAmount.toString())
      ]
    });
  }, [giffAmount, giffBalance, writeContract]);

  const handleMint = useCallback(async () => {
    writeContract({
      address: process.env.REACT_APP_PGIFF_CONTRACT_ADDRESS as `0x${string}`,
      abi: pGiffAbi || [],
      functionName: 'mint',
      args: [giffAmount],
      value: BigInt(10) * BigInt(giffAmount.toString())
    });
  }, [giffAmount, writeContract]);

  const handleButtonClick = useCallback(() => {
    if (status === 'pending' || isLoading) return;
    if (needAllowance) {
      handleApprove();
      return;
    }
    if (isMintable) {
      handleMint();
      return;
    }
  }, [handleApprove, handleMint, isLoading, isMintable, needAllowance, status]);

  return (
    <div className="flex w-full max-w-7xl items-start justify-center">
      <div className="flex w-full flex-col gap-y-4 rounded-xl border-2 border-theme bg-gray-700/20 px-4 py-6 shadow-2xl md:w-130">
        <TokenInput
          tokenType={TokenTypes.GIFF}
          classNames="rounded-md border-2 border-secondary shadow-lg"
          tokenImage={Giff}
          abi={giffAbi}
          api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${process.env.REACT_APP_GIFF_PAIR_ADDRESs}`}
          label="Send GIFF"
          contractAddress={process.env.REACT_APP_GIFF_CONTRACT_ADDRESS as `0x${string}`}
          tokenImageUrl={process.env.REACT_APP_SITE_URL + '/giff.webp'}
        />
        <TokenInput
          tokenType={TokenTypes.PGIFF}
          classNames="rounded-md border-2 border-theme shadow-lg"
          tokenImage={Pgiff}
          abi={pGiffAbi}
          api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${process.env.REACT_APP_PGIFF_PAIR_ADDRESs}`}
          label="Mint pGIFF"
          contractAddress={process.env.REACT_APP_PGIFF_CONTRACT_ADDRESS as `0x${string}`}
          tokenImageUrl={process.env.REACT_APP_SITE_URL + '/pgiff.webp'}
        />
        <div className="flex w-full flex-col gap-y-2 rounded-md border-2 border-gray-500 p-2">
          <div className="flex justify-between border-b border-gray-600 py-2 font-bold text-gray-500">
            <span>Protocol Fee:</span>
            <div className="item-center flex gap-x-2">
              <img src={Pls} alt="PLS token" className="h-6 w-6" />
              <span className="pt-0.25">
                {Number(plsAmountInteger.toFixed(1)).toLocaleString('en')} PLS
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <PriceDisplay
              title="PLS"
              api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${process.env.REACT_APP_WPLS_PAIR_ADDRESs}`}
            />
          </div>
        </div>
        {address ? (
          <button
            type="button"
            className="flex w-full items-center justify-center gap-x-2 rounded bg-theme px-4 py-1 font-semibold"
            onClick={handleButtonClick}
          >
            {status === 'pending' || isLoading ? (
              <Setting2 size={24} variant="Outline" className="animate-spin" />
            ) : needAllowance ? (
              'Approve GIFF'
            ) : isMintable ? (
              'Mint pGIFF'
            ) : (
              'Enter Amount'
            )}
          </button>
        ) : (
          <ConnectWallet />
        )}
      </div>
    </div>
  );
};

export default Minter;
