import { createContext, ReactNode, useContext, useState, useMemo } from 'react';

interface ITokenContext {
  giffAmount: BigInt;
  setGiffAmount: (amount: BigInt) => void;
  giffBalance: BigInt;
  setGiffBalance: (balance: BigInt) => void;
  pGiffAmount: BigInt;
  setPGiffAmount: (amount: BigInt) => void;
  plsPrice: string;
  setPlsPrice: (price: string) => void;
  giffPrice: string;
  setGiffPrice: (price: string) => void;
  pGiffPrice: string;
  setPGiffPrice: (price: string) => void;
  giffPriceInUSD: number;
  pGiffPriceInUSD: number;
  plsPriceInUSD: number;
  giffAmountInteger: number;
  plsAmountInteger: number;
}

export const TokenContext = createContext<ITokenContext>({} as ITokenContext);

export const DEFAULT_DECIMALS = 18;

interface ITokenContextProvider {
  children: ReactNode;
}

export const TokenContextProvider = (props: ITokenContextProvider) => {
  const { children } = props;

  const [giffAmount, setGiffAmount] = useState<BigInt>(0n);
  const [giffBalance, setGiffBalance] = useState<BigInt>(0n);
  const [pGiffAmount, setPGiffAmount] = useState<BigInt>(0n);
  const [plsPrice, setPlsPrice] = useState<string>('0');
  const [giffPrice, setGiffPrice] = useState<string>('0');
  const [pGiffPrice, setPGiffPrice] = useState<string>('0');

  const giffAmountInteger = useMemo(
    () => parseFloat(giffAmount.toString()) / 10 ** 18,
    [giffAmount]
  );
  const plsAmountInteger = useMemo(() => giffAmountInteger * 10, [giffAmountInteger]);

  const giffPriceInUSD = useMemo(
    () => parseFloat(giffPrice) * (parseFloat(giffAmount.toString()) / 10 ** DEFAULT_DECIMALS),
    [giffAmount, giffPrice]
  );
  const pGiffPriceInUSD = useMemo(
    () => parseFloat(pGiffPrice) * (parseFloat(giffAmount.toString()) / 10 ** DEFAULT_DECIMALS),
    [giffAmount, pGiffPrice]
  );
  const plsPriceInUSD = useMemo(
    () => parseFloat(plsPrice) * (parseFloat(giffAmount.toString()) / 10 ** DEFAULT_DECIMALS) * 10,
    [giffAmount, plsPrice]
  );

  const value: ITokenContext = useMemo(
    () => ({
      giffAmount,
      setGiffAmount,
      giffBalance,
      setGiffBalance,
      pGiffAmount,
      setPGiffAmount,
      plsPrice,
      setPlsPrice,
      giffPrice,
      setGiffPrice,
      pGiffPrice,
      setPGiffPrice,
      giffPriceInUSD,
      pGiffPriceInUSD,
      plsPriceInUSD,
      giffAmountInteger,
      plsAmountInteger
    }),
    [
      giffAmount,
      giffAmountInteger,
      giffBalance,
      giffPrice,
      giffPriceInUSD,
      pGiffAmount,
      pGiffPrice,
      pGiffPriceInUSD,
      plsAmountInteger,
      plsPrice,
      plsPriceInUSD
    ]
  );

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>;
};

export const useTokenContext = () => useContext(TokenContext);
